import React from 'react';
import Layout from '../components/Layout';
import womanUsingComputer from '../assets/images/woman-using-computer.jpg';
import piano from '../assets/images/woman-wearing-yellow-shirt-leaning-on-grand-piano.jpg';
import womanOnPhone from '../assets/images/woman-using-her-smartphone.jpg';
import calendar from '../assets/images/inspirational-quotes-on-a-planner.jpg';
const HowItWorks = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Get a moving quote online</h2>
          <p>
            Why bother getting just one quote. We will get you 3 right now in
            just 1 call.{' '}
          </p>
        </div>
      </header>

      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img
              src={womanUsingComputer}
              alt="woman using her laptop computer to submit her information and get a free moving quote"
            />
          </a>
          <div className="content">
            <h2 className="major">Submit your information</h2>
            <p>
              Use our site to put in your email adress, phone number, the zip
              code you are moving from and to, and how many bedrooms your home
              is so we can make sure to ask all the right questions.If you have
              a 5 bedroom home but you are only shipping a few pieces you can
              select partial move. Don't worry if the zipcodes you give us
              aren't exact, we just want to have a good idea so we can hit the
              ground running. Use the little message box and tell us anything
              else special you'd like to give us the heads up on.
            </p>
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="#moving-quote" className="image">
            <img src={piano} alt="woman in yellow shirt resting on her piano" />
          </a>
          <div className="content">
            <h2 className="major">
              Is your piano moving? Do you need auto shipping?
            </h2>
            <p>
              Use the little checkboxes to let us know about any additional
              <a> specialty items</a> you will need help moving. Maybe you have
              an organ or a pinball machine. Do your kids like to ride around on
              4 wheelers? Happy to ride the hog out to Sturgis but not trying to
              drive your motorcycle from Manhattan to Palo Alto? Want to fly to
              your new home and have us ship the Lexus? Maybe you would like to
              have your SUV shipped to your new home so you and your lucky
              spouse can take that roadtrip you always wanted to with the top
              down. From pool tables to Porsches we can take care of all these
              items for you!
            </p>
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="#moving-quote" className="image">
            <img
              src={womanOnPhone}
              alt="woman sittin with her laptop using her phone to call movers"
            />
          </a>
          <div className="content">
            <h2 className="major">
              Speak with one of our professional moving agents
            </h2>
            <p>
              Let us know what's the best way to reach you, or even schedule a
              time for a call and we will reach out and guide you through the
              moving process from day one up to the day of the delivery. Your
              moving agent will make sure you understand all the ins and outs of
              the moving process, and go over every little detail with you so
              that we can guarantee that your move goes excellent from the
              moment the movers arrive, until the moment your delivery arrives
              and we set your entire house up for you, picture perfect exactly
              how you want it set up. When you decide to work with us, you will
              have your feet up on the ottoman, reading your favorite book, or
              watching your favorite tv show before you know it.
            </p>
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </section>
      <section id="three" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="#moving-quote" className="image">
            <img src={calendar} alt="inspirational quote on a calendar" />
          </a>
          <div className="content">
            <h2 className="major">We find the best moving company near you</h2>
            <p>
              Based on your needs we will find you the best moving company in
              your area. Need to plan moving on a budget? We got you. Need last
              minute movers? Sure. Whatever it is you are looking for in a
              moving company, we will search through our network of cross
              country movers to find the right fit. The best part is that all of
              the moving companies we refer you to are preverified and have
              already performed hundreds, and even thousands of moves that have
              gone off without a hitch so you can rest easy and know you are in
              good hands. Once we have found you the best deal, we get you a
              contract directly with the movers, and get your move scheduled.
            </p>
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </section>
    </section>
  </Layout>
);
export default HowItWorks;
